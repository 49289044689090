export const colors = {
  direction: 'ltr',

  lighGray: '#404040',
  white: '#FFFFFF',
  darkBlack: '#151515',
  lightBlack: '#333333',
  darkBlue: '#1750AF',
  lightBlue: '#2678FF',
  hoverBackGround: '#F9F9F9', // light grey
  formBorder: '#E5E5E5', // light grey
  placeHolderFontColor: '#9B9B9B', //dark grey
  descrptionColor: '#6D6D6D', // light grey
  error: '#AD0000', // red
  light: '#E8EEF7',
  hover: '#f5f5f5',
  orange: '#DA8500',

  primary: {
    main: '#2678FF',
    dark: '#1750AF',
    light: 'rgba(23, 80, 175, 0.1)',
    veryLight: '#2678FF05',
    disabled: '#A2B9DF',
    opacity: '#ffffff80',
    darker: '#001436',
  },
  green: {
    main: '#5CB488',
    light: '#5CB48830',
  },
  red: {
    main: '#AD0000',
    light: '#AD000030',
    orange: '#CD6200',
    disabled: '#8F8F8F',
  },
  grey: {
    500: '#8F8F8F',
    400: '#F2F2F2',
    300: '#DDDDDD',
    600: '#F6F6F6',
    700: '#757575',
    800: '#808080',
    900: '#A5A5A5',
    100: '#6D6D6D',
    200: '#d5d5d5',
    1000: '#F0F1F5',
    placeholder: '#d5d5d5',
    opacity: '#33333320',
  },
  black: {
    dark: '#000000',
    main: '#151515',
    alt: '#333333',
    opacity: '#00000060',
  },
  white: {
    main: '#ffffff',
  },
}
