import React, { useEffect } from 'react';
import styled from 'styled-components';

const StyledContainer = styled.div`
  height: ${(props) => props.height}px;
  overflow: hidden;
`;
const Flipped = styled.div`
  transition: transform 1s ease-in-out, opacity 1.1s ease-in-out;
  transform: translateY(-${(props) => props.currentIndex * props.height}px);
`;
const Section = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  font-size: ${(props) => props.height}px;
  transition: opacity 0.7s ease-in-out;
  opacity: ${(props) => (props.isDisplayed ? '1' : '0')};
`;

function MultipleFlipTitle({ height, texts = [], props, delay = 3000 }) {
  const [currentIndex, setCurrentIndex] = React.useState(0);
  const timeoutRef = React.useRef(null);
  function resetTimeout() {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  }
  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      if (currentIndex === texts.length - 1) {
        setCurrentIndex(0);
      } else {
        setCurrentIndex(currentIndex + 1);
      }
    }, delay);

    return () => {
      resetTimeout();
    };
  }, [currentIndex, texts.length, delay]);
  return (
    <StyledContainer height={height}>
      <Flipped currentIndex={currentIndex} height={height}>
        {texts.map((c, index) => (
          <Section
            key={c?.id}
            currentIndex={currentIndex}
            height={height}
            isDisplayed={index === currentIndex}
          >
            {c.text}
          </Section>
        ))}
      </Flipped>
    </StyledContainer>
  );
}

export default MultipleFlipTitle;
