export const API_URL =
  process.env.NODE_ENV === 'production'
    ? 'https://www.stage.service.thefinstreet.co.uk'
    : 'https://www.stage.service.thefinstreet.co.uk';



export const MOVE_TO_STAGING = () => {
  window.open('https://www.stage.dashboard.thefinstreet.co.uk/');
}
