import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import Vedio from '../../../assets/waiting.mp4'
import PrimaryButton from '../../../shared/buttons/PrimaryButton'
import CustomContainer from '../../../shared/customContainer/CustomContainer'
import { BsArrowUpRight } from 'react-icons/bs'
import MultipleFlipTitle from '../../../modules/landing/flipTitle/MultipleFlipTitle'
import { MOVE_TO_STAGING } from '../../../api/config'

const MainContainer = styled.div`
  width: 100%;
  height: ${props => (props.hideContent ? '100vh' : 'calc(100vh - 84px)')};
  min-height: 600px;
  position: relative;
  overflow: hidden;
  ::before {
    content: '';
    position: absolute;
  }
`

const VideoItem = styled.video`
  object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`

const LeftSectionContainer = styled.div`
  width: 100%;
  height: calc(100vh - 100px);
  display: flex;
  justify-content: flex-start;
  align-items: center;
  @media (max-width: 600px) {
    align-items: flex-start;
  }
`

const Description = styled.div`
  color: #6d6d6d;
  font-size: 1.2rem;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
  width: 70%;
  font-family: ${props => props.theme.fonts.head.roman};
  @media (max-width: 600px) {
    text-align: center;
    width: 100%;
    color: ${props => props.theme.colors.black.main};
    font-size: 1.2rem;
  }
`

const Title = styled.div`
  font-size: 3.1rem;
  font-weight: 700;
  color: #151515;
  margin-bottom: 1rem;
  font-family: ${props => props.theme.fonts.head.bold};
  @media (max-width: 600px) {
    text-align: center;
    font-size: 2.5rem;
  }
`

const MovableTitle = styled.div`
  font-size: 3.1rem;
  font-weight: 700;
  font-family: ${props => props.theme.fonts.head.bold};
  color: #1750ad;
  width: 70%;
  @media (max-width: 600px) {
    font-size: 2.5rem;
    width: 100%;
    text-align: center;
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: -webkit-linear-gradient(
    110.66deg,
    #ffffff 27.45%,
    rgba(255, 255, 255, 0) 102.92%
  );
  background-image: -moz-linear-gradient(
    110.66deg,
    #ffffff 27.45%,
    rgba(255, 255, 255, 0) 102.92%
  );
  background-image: -ms-linear-gradient(
    110.66deg,
    #ffffff 27.45%,
    rgba(255, 255, 255, 0) 102.92%
  );

  background-image: -o-linear-gradient(
    110.66deg,
    #ffffff 27.45%,
    rgba(255, 255, 255, 0) 102.92%
  );

  background: linear-gradient(
    110.66deg,
    #ffffff 27.45%,
    rgba(255, 255, 255, 0) 102.92%
  );

  z-index: -1;
`

const ButtonText = styled.span``

const CustomPrimaryButton = styled(PrimaryButton)`
  @media (max-width: 600px) {
    margin: 0 auto;
    padding: 1rem 2rem;
  }
`

export default function HeroSection({
  onButtonClick = () => { },
  hideContent = false,
}) {
  return (
    <MainContainer hideContent={hideContent}>
      <VideoItem playsInline autoPlay loop muted preload="auto">
        <source src={Vedio} type="video/mp4" playsInline autoPlay loop muted />
      </VideoItem>
      <Overlay />
      {!hideContent && (
        <CustomContainer
          maxWidth="lg"
          style={{
            height: '100%',
          }}
        >
          <Grid
            container
            style={{
              minHeight: '100%',
            }}
          >
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <LeftSectionContainer>
                <Section>
                  <div>
                    <MultipleFlipTitle
                      height={65}
                      texts={['Liquid', 'Democratise', 'Access', 'Swift'].map(
                        c => ({
                          id: c.id,
                          text: <MovableTitle>{c}</MovableTitle>,
                        }),
                      )}
                      autofip={true}
                      delay={3000}
                      currentIndex={0}
                    />
                    <div className="content">
                      <Title>Private Markets.</Title>
                      <Description>
                        Join in the revolution of unlocking direct access to
                        capital in private markets.
                      </Description>
                      <CustomPrimaryButton onClick={onButtonClick}>
                        <ButtonText onClick={MOVE_TO_STAGING}>Join Us</ButtonText>
                      </CustomPrimaryButton>
                    </div>
                  </div>
                </Section>
              </LeftSectionContainer>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}></Grid>
          </Grid>
        </CustomContainer>
      )}
    </MainContainer>
  )
}

const Section = styled.section`
  padding-top: 2.6rem;
  .main {
    #bgvid {
      width: 100%;

      object-fit: cover;
      position: absolute;
      /* width:100vw ;
  height:100vh ; */
      /* max-height:100vh; */

      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      z-index: -1;
    }

    .content {
    }
    .header {
      max-width: 817px;

      font-family: 'Sequel Sans-BoldBody';
      font-style: normal;
      font-weight: 525;
      font-size: 3.5rem;
      line-height: 50px;
      text-transform: capitalize;
      overflow: hidden;
      color: white;
      .wrapper {
        box-sizing: content-box;

        height: 75px;

        display: grid;
        flex-shrink: row;
        border-radius: 8px;
        color: white;
        box-shadow: 0 20px 25px rgba(0, 0, 0, 0.2);
      }
      .words {
        overflow: hidden;
      }
      span {
        color: white;
        color: #1750af;
        display: block;
        height: 100%;
        animation: spin_words 7s infinite;
      }
      /* .words{
  overflow:hidden;
}
        span {
          color: white;
          color: #2678ff;
          display:block ;
          height:100% ;
          font-size: 2rem;
          animation: spin_words 6s infinite;

        } */
      @keyframes spin_words {
        10% {
          transform: translateY(-112%);
        }
        25% {
          transform: translateY(-100%);
        }
        35% {
          transform: translateY(-212%);
        }
        50% {
          transform: translateY(-200%);
        }
        60% {
          transform: translateY(-312%);
        }
        75% {
          transform: translateY(-300%);
        }
        85% {
          transform: translateY(-412%);
        }
        100% {
          transform: translateY(-400%);
        }
      }
    }

    p > li {
      list-style-type: none;
      padding-top: 15px;
      max-width: 410px;

      font-family: 'Sequel Sans-RomanBody';
      font-style: normal;
      font-weight: 410;
      font-size: 2rem;
      line-height: 2rem;
      text-transform: capitalize;

      color: white;
    }
  }
  img {
    max-width: 100%;
    margin-right: 20px;
  }
  @media screen and (max-width: 768px) {
    .main {
      margin-top: 5rem;
      flex-wrap: wrap;
      .header {
        .words {
          overflow: visible;
        }
        .wrapper {
          height: 100px;
        }
      }
      #bgvid {
        width: 100%;
        height: 100vh;
      }
    }
    .ticker-wrapper {
      .text-update p {
        width: 400%;
      }
    }
    @media screen and (max-width: 426px) {
      .main {
        .content {
          margin: 2px 2px 2px 15px;
          padding: 2px 5px;
          .header {
            /* width:2rem ; */
            .wrapper {
              height: 2rem;
            }
            .words {
              font-size: 2.9rem;
            }
          }
        }
      }

      @media screen and (max-width: 321px) {
        .main {
          .content {
            .header {
              /* width:2rem ; */

              .words {
                font-size: 2.4rem;
              }
            }
          }
        }
      }
    }
  }
`
