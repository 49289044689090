import React, { Children } from 'react'
import styled, { keyframes } from 'styled-components'

const Button = styled.button`
  background-color: rgb(23, 80, 175);
  color: rgb(255, 255, 255);
  display: flex;

  border: none;
  padding: ${props => `${props.paddingTop}rem ${props.paddingRight}rem`};
  margin-top: ${props => `${props.marginTop && props.marginTop}`};
  border-radius: 8px;
  font-size: 16px;
  font-weight: 500;
  font-family: ${props => props.theme.fonts.head.medium};
  transition: all 0.3s ease-in-out;
  :disabled {
    opacity: 0.5;
  }
`
const spin = keyframes`
 0% { 
    transform: rotate(0deg);
  }
 100% { 
    transform: rotate(360deg);
  }
`
const Loading = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-top-color: transparent;
  animation: ${spin} 1s linear infinite;
  margin-right: 10px;
`

export default function PrimaryButton({
  children,
  marginTop,
  paddingTop = '1.1',
  paddingRight = '2.4',
  loading = false,
  ...props
}) {
  return (
    <Button
      marginTop={marginTop}
      paddingTop={paddingTop}
      paddingRight={paddingRight}
      {...props}
    >
      {loading ? <Loading /> : null}
      <span>{children}</span>
    </Button>
  )
}
