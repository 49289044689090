export const fonts = {
  body: {
    black: 'Sequel Sans-blackBody',
    bold: 'Sequel Sans-BoldBody',
    book: 'Sequel Sans-BookBody',
    light: 'Sequel Sans-lightBody',
    medium: 'Sequel Sans-MediumBody',
    roman: 'Sequel Sans-RomanBody',
    semiBold: 'Sequel Sans-SemiBold',
  },
  head: {
    black: 'Sequel Sans-blackHead',
    bold: 'Sequel Sans-BoldHead',
    book: 'Sequel Sans-BookHead',
    light: 'Sequel Sans-lightHead',
    medium: 'Sequel Sans-MediumHead',
    roman: 'Sequel Sans-RomanHead',
    semiBold: 'Sequel Sans-SemiBold',
  },
};
